<template>
    <v-list dense nav>
        <template v-if="isViewReady">
            <v-list-item v-if="list.length === 0">No apps.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <RealmAppListItem :attr="item" :accountId="accountId" :realmId="realmId" :link="!selectOne"></RealmAppListItem>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
import RealmAppListItem from '@/components/realm-dashboard/RealmAppListItem.vue';

export default {
    components: {
        RealmAppListItem,
    },

    props: {
        // optional; use this when you want to use the realm list to select an app; emits 'selected' when user taps on an app
        selectOne: {
            type: Boolean,
            default: false,
        },
        accountId: {
            type: String,
        },
        realmId: {
            type: String,
        },
        list: {
            type: Array,
        },
    },

    data: () => ({
        list: null,
    }),

    computed: {
        isViewReady() {
            return Array.isArray(this.list);
        },
    },

    methods: {
        /*
        async loadRealmAppList() {
            try {
                this.$store.commit('loading', { loadRealmAppList: true });
                const response = await this.$client.accountRealm(this.$route.params.accountId, this.$route.params.realmId).realmApp.search();
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadRealmAppList failed', err);
            } finally {
                this.$store.commit('loading', { loadRealmAppList: false });
            }
        },
        */
        onClickItem(realmId) {
            if (this.selectOne) {
                this.$emit('selected', { realmId });
            }
        },
    },

    mounted() {
        // this.loadRealmAppList();
    },
};
</script>
