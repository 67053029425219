<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :accountId="accountId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'app-dashboard', params: { realm: this.$route.params.realmId } }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'app-account-list', params: { realm: this.$route.params.realmId } }">Accounts</router-link>
                    <router-link :to="{ name: 'realm-dashboard', params: { accountId: this.$route.params.accountId, realmId: this.$route.params.realmId } }">Realm</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="realm">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card>
                        <v-toolbar color="indigo" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Applications
                        </v-toolbar-title>
                        <v-spacer/>
                        <v-btn icon @click="createNewItemDialog = true">
                            <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                        </v-btn>
                        </v-toolbar>
                        <RealmAppList :list="list" :accountId="accountId" :realmId="realmId"></RealmAppList>
                    </v-card>
                    <v-dialog v-model="createNewItemDialog" max-width="600">
                    <v-card tile elevation="4" class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="indigo--text">Add Application</v-toolbar-title>
                        </v-toolbar>
                        <!-- <v-card-text class="px-5">
                        </v-card-text> -->
                        <v-form @submit.prevent="createItem" @keyup.enter.native.prevent="createItem" class="px-2">
                            <v-text-field v-model="newItemDisplayName" label="Name" hint="The name of the application; for display to users" ref="newItemDisplayNameInput"></v-text-field>
                            <v-checkbox v-model="newItemActive" label="Active" hint="Make this application available to users" ref="newItemActiveInput"></v-checkbox>
                            <v-text-field v-model="newItemBrandprofile" label="BrandProfile" hint="The unique brandprofile ID of this application to display logo" ref="newItemBrandprofileInput"></v-text-field>
                            <v-text-field v-model="newItemSsoTokenUrl" label="SSO Token URL" hint="Where to redirect users to sign in to this application; for example http://example.com/login" ref="newItemSsoTokenUrlInput"></v-text-field>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="indigo white--text" @click="createItem" :disabled="!isNewItemFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="createNewItemDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/RealmBar.vue';
import RealmAppList from '@/components/realm-dashboard/RealmAppList.vue';

export default {
    components: {
        RealmAppList,
        AccessDeniedOverlay,
        RealmBar,
    },
    data: () => ({
        realm: null,
        forbiddenError: false,
        list: [],
        error: null,
        createNewItemDialog: false,
        newItemDisplayName: null,
        newItemActive: false,
        newItemBrandprofile: null,
        newItemSsoTokenUrl: null,
        submitTimestamp: null,
    }),
    computed: {
        accountId() {
            return this.$route.params.accountId;
        },
        realmId() {
            return this.$route.params.realmId;
        },
        isNewItemFormComplete() {
            return typeof this.newItemDisplayName === 'string' && this.newItemDisplayName.length > 0;
        },
    },
    watch: {
        createNewItemDialog(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.newItemDisplayName = '';
                this.newItemActive = false;
                this.newItemBrandprofile = '';
                this.newItemSsoTokenUrl = '';
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('newItemDisplayNameInput'); }, 1);
                });
            }
        },
        focus() {
            this.loadAppList();
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadRealm() {
            try {
                this.$store.commit('loading', { loadRealm: true });
                const result = await this.$client.account(this.$route.params.accountId).realm.get({ id: this.$route.params.realmId });
                console.log(`realm/loginshield.vue loadRealm result: ${JSON.stringify(result)}`);
                if (result) {
                    this.realm = result;
                }
            } catch (err) {
                console.error('realm/loginshield.vue loadRealm failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm', message });
            } finally {
                this.$store.commit('loading', { loadRealm: false });
            }
        },
        async loadAppList() {
            const match = { account_id: this.$route.params.accountId, realm_id: this.$route.params.realmId };
            const result = await this.$client.accountRealm(this.$route.params.accountId, this.$route.params.realmId).realmApp.search(match);
            console.log(`loadAppList: ${JSON.stringify(result)}`);
            if (Array.isArray(result?.list)) {
                this.list = result.list;
            }
        },
        async createItem() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createItem: true });
                const item = {
                    display_name: this.newItemDisplayName,
                    is_active: this.newItemActive,
                    brandprofile: this.newItemBrandprofile,
                    sso_token_url: this.newItemSsoTokenUrl,
                };
                const response = await this.$client.accountRealm(this.$route.params.accountId, this.$route.params.realmId).realmApp.create(item);
                if (response?.isCreated && response.id) {
                    this.createNewItemDialog = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    // this.clientList.push(response.item);
                    this.loadAppList();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createItem: false });
            }
        },
        // async openEditItemDialog(item) {
        //     this.$router.push({ name: 'realm-edit-app', params: { accountId: this.$route.params.accountId, realmId: this.$route.params.realmId }, query: { id: item.id } });
        // },
    },
    mounted() {
        this.loadRealm();
        this.loadAppList();
    },
};
</script>
