<template>
    <v-list-item-content>
        <v-list-item-title>
        <template v-if="link">
            <span><router-link :to="{ name: 'realm-app-view', params: { accountId, realmId, appId: id } }">{{ displayName }}</router-link></span>
        </template>
        <template v-if="!link">
            <span>{{ displayName }}</span>
        </template>
        <v-chip label x-small color="green darken-2" text-color="white" class="mx-2" v-if="active">Active</v-chip><v-chip label x-small color="amber darken-1" text-color="black" variant="outlined" class="mx-2" v-else>Inactive</v-chip>
        </v-list-item-title>
        <v-list-item-subtitle>
        <span class="text-caption">{{ id }}</span>
        </v-list-item-subtitle>
        <!-- <template v-if="isPermitSystemAdmin">
        </template> -->
    </v-list-item-content>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

    props: {
        // when true, the item is a link to the user view
        link: {
            type: Boolean,
            default: false,
        },
        accountId: {
            type: String,
        },
        realmId: {
            type: String,
        },
        attr: {
            type: Object,
        },
    },

    computed: {
        ...mapGetters({
            // isPermitSystemAdmin: 'isPermitSystemAdmin',
        }),
        id() {
            return this.attr.id;
        },
        active() {
            return this.attr.is_active;
        },
        displayName() {
            return this.attr.display_name;
        },
        brandprofile() {
            return this.attr.brandprofile;
        },
    },

};
</script>
